import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import Layout from '../components/layout'
import { Flask, Bolt, Code, Keyboard, Cogs, Rocket } from '../components/icons'

import img1 from '../assets/images/mariana-b-604752-unsplash.jpg'

class Homepage extends React.Component {
  render() {
    const siteTitle = 'Lehi Labs Makerspace'

    return (
      <Layout>
        <Helmet title={siteTitle}>
          <meta property="og:url" content="https://lehilabs.org" />
          <meta property="og:title" content="Lehi Labs makerspace" />
          <meta
            property="og:description"
            content="A space for you to innovate and develop STEAM/STEM projects"
          />
          <meta
            property="og:image"
            content={withPrefix('img/lehilabs-green.png')}
          />
          <meta property="og:image:type" content="image/png" />
          <html lang="en" />
        </Helmet>

        <section id="one" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>Our Mission</h2>
              </header>
              <p>
                Our mission is to offer a place for community members interested
                in Science, Technology, Engineering, Art and Math fields of
                study (STEAM). The space will provide tools, training and other
                resources, and with invite the community to innovate,
                collaborate, and create with&nbsp;us.
              </p>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={img1} alt="" />
              </span>
            </div>
          </div>
        </section>

        <section id="two" className="main style3">
          <div className="grid-wrapper">
            <div className="col-6" aria-hidden="true">
              <ul className="major-icons">
                <li>
                  <span className="icon style1 major">
                    <Flask size="2x" />
                  </span>
                </li>
                <li>
                  <span className="icon style2 major">
                    <Bolt size="2x" />
                  </span>
                </li>
                <li>
                  <span className="icon style3 major">
                    <Code size="2x" />
                  </span>
                </li>
                <li>
                  <span className="icon style4 major">
                    <Keyboard size="2x" />
                  </span>
                </li>
                <li>
                  <span className="icon style5 major">
                    <Cogs size="2x" />
                  </span>
                </li>
                <li>
                  <span className="icon style6 major">
                    <Rocket size="2x" />
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>Where to begin</h2>
              </header>
              <p>
                Here are some beginner projects and ideas to get you started:
              </p>
              <ul
                style={{ width: 350, margin: '0 auto 2em', textAlign: 'left' }}
              >
                <li>Design and plan out your own prototype idea or product</li>
                <li>Build your own circuit</li>
                <li>3D print a replacement part</li>
                <li>Tinker with Arduinos, robotics, and electronics parts</li>
                <li>Build and program your own mechanical keyboard</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="three" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>Current Status</h2>
              </header>
              <p>
                We are currently still bootstrapping this endeavor. That means
                we are still creating our business proposal to attract sponsors
                and raise funds, searching for associates and a venue, and
                organizing meetups around the Lehi area.
              </p>
              <p>
                Want to help? Please{' '}
                <a href="https://join.slack.com/t/lehilabs/shared_invite/enQtNDg5NDIwMzI2NjkxLWViZDE4MDgzNzY3OTFhMjg4OGRiOThiMjQ0NmYyNWY2Njc0MTFjZjU5MmVlZjY0YzY4ZmM5NGI1ODU5NmUzNzk">
                  join our Slack workspace
                </a>{' '}
                or <a href="mailto:lehilabs@gmail.com">contact us via email</a>!
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Homepage
